import {createContext} from "react";

export const customizerContext = createContext<BuildCustomizerContext>({
    id: null,
    sections: [],
    product: null,
    selectedSection: null,
    setSelectedSection: (section: BuildCustomizerSection | null) => {},
    setSelectedSectionOption: (section: BuildCustomizerSection, option: BuildCustomizerOption) => {},
});
