import React, {useContext, useState} from 'react';
import {Dialog} from '@headlessui/react';

import {modalContext} from "@/widget/contexts/ModalContext";
import {customizerContext} from '@/widget/contexts/CustomizerContext';
import CustomizerOptionPicker from '../CustomizerOptionPicker';
import CloseIcon from '@/widget/icons/CloseIcon';

export default function AvailableOptionsMobileModal() {
    const {activeModal, setActiveModal, modalData} = useContext<BuildModalContext>(modalContext);
    const {setSelectedSectionOption} = useContext<BuildCustomizerContext>(customizerContext);
    const [loading, setLoading] = useState(false);

    const section = modalData.section as BuildCustomizerSection;
    const option = modalData.option as BuildCustomizerOption;

    const isActive = activeModal === 'AvailableOptionsMobileModal';

    const closeModal = () => {
        setActiveModal(null);
    };

    return (
        <Dialog
            open={isActive}
            as="div"
            className="RGB-Dialog"
            onClose={() => {}}
        >
            <div className="RGB-Dialog__Main">
                <Dialog.Overlay className="RGB-Dialog__Overlay"/>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="RGB-Dialog__Center" aria-hidden="true">&#8203;</span>

                <div className="RGB-Dialog__Card">
                    <Dialog.Title as="h3" className="RGB-Dialog__Card__Title">
                        Available Options <button className="RGB-Dialog__CloseBtn" onClick={closeModal}>
                            <CloseIcon/>
                        </button>
                    </Dialog.Title>
                    <div className="RGB-Dialog__Card__Body" style={{marginTop: '0.5rem'}}>
                        <div className="RGB-Container">
                            <CustomizerOptionPicker/>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
