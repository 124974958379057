import axios, {AxiosResponse} from 'axios';
import {CartItemsResponse, CartState} from "@admin/types/cart";

const Http = axios.create();
Http.defaults.timeout = 3500;

export default new class Cart {
    async add(lineItems: Object): Promise<AxiosResponse<CartItemsResponse>> {
        return Http.post('/cart/add.js', {
            items: lineItems
        });
    }

    async update(data: object): Promise<AxiosResponse<CartState>> {
        return Http.post('/cart/update.js', {
            updates: data
        }).then();
    }

    async get(): Promise<AxiosResponse<CartState>> {
        return Http.get('/cart.js');
    }

    /**
     * Remove items already in the cart that matches a given build ID
     * @param buildId
     */
    async removeBuildItems(buildId: number) {
        const cart = await this.get();

        for (let lineItem of cart.data.items) {
            if (lineItem.properties && lineItem.properties['_build_id']) {
                if (lineItem.properties['_build_id'] == buildId.toString()) {
                    let update: GenericObject = {};
                    update[lineItem.id] = 0;
                    await this.update(update);
                }
            }
        }
    }
};
