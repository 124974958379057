/* eslint-disable max-len */
const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" fill="#ffffff" viewBox="0 0 16 15">
        <g>
            <g>
                <g>
                    <path d="M14.566 7.38a6.307 6.307 0 0 1-6.306 6.306C4.776 13.686 2 10.863 2 7.38c0-3.484 2.776-6.307 6.26-6.307a6.314 6.314 0 0 1 6.306 6.307zm1.023 0a7.33 7.33 0 1 0-7.33 7.33 7.336 7.336 0 0 0 7.33-7.33z"/></g><g><path d="M6.33 9.82c-.135 0-.265-.054-.33-.15a.465.465 0 0 1 0-.724l3.829-3.859c.171-.187.515-.182.71.013.195.195.2.508.012.71L6.692 9.67a.498.498 0 0 1-.361.15z"/></g><g><path d="M10.198 9.82a.498.498 0 0 1-.362-.15L6 5.81a.483.483 0 0 1 0-.71.499.499 0 0 1 .7-.013l3.86 3.86a.511.511 0 0 1-.362.872z"/>
                </g>
            </g>
        </g>
    </svg>
);

export default CloseIcon;
