import React, {useContext} from "react";
import CustomizerSection from "@widget/components/CustomizerSection";
import {customizerContext} from "@widget/contexts/CustomizerContext";

export default function Customizer() {
    const {sections} = useContext<BuildCustomizerContext>(customizerContext);

    return (
        <div className="RGB-Customizer">
            <div className="RGB-Card">
                <div className="d3 RGB-Card-Header">Customize</div>
                <div className="RGB-Card-Body">
                    <div className="RGB-Container">
                        {sections
                            .sort((a, b) => a.position > b.position ? 1 : -1)
                            .filter(section => (!section.excluded)).map((section) => (
                                <CustomizerSection
                                    section={section}
                                    key={section.id}/>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
