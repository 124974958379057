/* eslint-disable max-len */
const ToadIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={15}
        viewBox="0 0 17 15"
    >
        <g>
            <g>
                <g>
                    <path d="M7.453 11.302c-.221.003-.453-.128-.51-.302-.086-.234-.041-.47.114-.628a.553.553 0 0 1 .943.383.553.553 0 0 1-.547.547z" />
                </g>
                <g>
                    <path d="M10.089 11.297a.539.539 0 1 0 0-1.077.539.539 0 0 0 0 1.077z" />
                </g>
                <g>
                    <path d="M10.27 8.467a2.291 2.291 0 0 1 0 4.583H7c-1.257 0-2.283-1.05-2.283-2.291A2.29 2.29 0 0 1 7 8.467zm0 5.66a3.369 3.369 0 0 0 2.918-5.053C12.586 8 11.474 7.39 10.27 7.39H7a3.367 3.367 0 0 0-3.36 3.369A3.367 3.367 0 0 0 7 14.128z" />
                </g>
                <g>
                    <path d="M14.388 9.518h-1.925A.535.535 0 0 1 11.924 9c0-.318.241-.56.539-.56h1.925c.612 0 1.128-.504 1.128-1.127 0-3.313-2.72-6.08-6.077-6.084H7.923C4.565 1.233 1.843 4 1.84 7.313c0 .3.161.586.33.798.212.211.499.33.831.33h1.82c.298 0 .539.241.539.559a.535.535 0 0 1-.539.518H2.93A2.212 2.212 0 0 1 .74 7.313C.74 3.346 4 .13 7.923.13H9.44a7.183 7.183 0 0 1 7.183 7.183A2.34 2.34 0 0 1 16 8.881c-.456.414-1 .643-1.612.637z" />
                </g>
                <g>
                    <path d="M2.3 7.549c-.412 0-.822-.079-1.206-.23a.539.539 0 1 1 .366-1.006c.258.105.54.158.812.158A2.17 2.17 0 0 0 4.362 5c.286-1-.062-1.94-.855-2.472-.227-.174-.28-.528-.12-.731a.539.539 0 0 1 .724-.16 3.232 3.232 0 0 1 1.282 3.626C5 6.622 3.723 7.549 2.301 7.549z" />
                </g>
                <g>
                    <path d="M16 7.536a4.657 4.657 0 0 1-4.3-4.626c0-.568.104-1.13.3-1.66a.569.569 0 0 1 .434-.334c.192-.028.384.084.503.2.12.153.15.357.063.537-.145.4-.226.826-.223 1.257a3.592 3.592 0 0 0 3.283 3.548c.296.027.517.286.496.542-.023.32-.257.537-.556.536z" />
                </g>
            </g>
        </g>
    </svg>
);

export default ToadIcon;
