import React, {useEffect, useState} from "react";
import Customizer from "@widget/components/Customizer";
import CustomizerHeader from "@widget/components/CustomizerHeader";
import Http from "@widget/lib/Http";
import CustomizerOptionPicker from "@widget/components/CustomizerOptionPicker";
import {customizerContext} from "@widget/contexts/CustomizerContext";
import {modalContext} from "@widget/contexts/ModalContext";
import MoreInfoModal from "@widget/components/Modals/MoreInfoModal";
import AvailableOptionsMobileModal from "./Modals/AvailableOptionsMobileModal";

export default function CustomizerWidget() {
    const [customizerId, setCustomizerId] = useState<number | null>(null);
    const [customizerProduct, setCustomizerProduct] = useState<BuildCustomizerProduct | null>(null);
    const [customizerSections, setCustomizerSections] = useState<BuildCustomizerSection[]>();
    const [selectedSection, setSelectedSection] = useState<BuildCustomizerSection | null>(null);
    const [variableState, setVariableState] = useState<GenericObject>();
    const [activeModal, setActiveModal] = useState<string | null>(null);
    const [modalData, setModalData] = useState({});
    const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

    const setSelectedSectionOption = (section: BuildCustomizerSection, option: BuildCustomizerOption): void => {
        const sections = customizerSections?.map(customizerSection => {
            if (customizerSection.id == section.id) {
                customizerSection.selected_option = option;
            }

            return customizerSection;
        });

        setCustomizerSections(sections);
        updateSectionVariableState();
    };

    const updateSectionVariableState = (): void => {
        console.log('updateSectionVariableState');

        const varState: GenericObject = {};
        if (customizerSections) {
            for (let customizerSection of customizerSections) {
                if (!customizerSection.excluded && customizerSection.variable_name) {
                    varState[customizerSection.variable_name] = customizerSection.selected_option
                        ? customizerSection.selected_option.variable_value
                        : null;
                }

            }
        }

        const sections = customizerSections?.map(customizerSection => {
            if (varState
                && customizerSection.variable_condition_enabled
                && customizerSection.variable_condition_name
                && customizerSection.variable_condition_name
                && varState[customizerSection.variable_condition_name]) {

                const conditionValue = varState[customizerSection.variable_condition_name];
                customizerSection.excluded = false;

                if (customizerSection.variable_condition_operand === 'equals') {
                    if (conditionValue == customizerSection.variable_condition_compare_value) {
                        customizerSection.excluded = true;
                    }
                } else if (customizerSection.variable_condition_operand === 'not_equals') {
                    if (conditionValue != customizerSection.variable_condition_compare_value) {
                        customizerSection.excluded = true;
                    }
                }
            }

            return customizerSection;
        });

        if (sections) {
            for (let customizerSection of sections) {
                if (!customizerSection.excluded && customizerSection.variable_name) {
                    varState[customizerSection.variable_name] = customizerSection.selected_option
                        ? customizerSection.selected_option.variable_value
                        : null;
                }
            }
        }

        setVariableState(varState);
    };

    const excludeNonMatchingSections = (): void => {
        console.log('excludeNonMatchingSections');

        const sections = customizerSections?.map(customizerSection => {
            if (variableState
                && customizerSection.variable_condition_enabled
                && customizerSection.variable_condition_name
                && customizerSection.variable_condition_name
                && variableState[customizerSection.variable_condition_name]) {

                const conditionValue = variableState[customizerSection.variable_condition_name];
                customizerSection.excluded = false;

                if (customizerSection.variable_condition_operand === 'equals') {
                    if (conditionValue == customizerSection.variable_condition_compare_value) {
                        customizerSection.excluded = true;
                    }
                } else if (customizerSection.variable_condition_operand === 'not_equals') {
                    if (conditionValue != customizerSection.variable_condition_compare_value) {
                        customizerSection.excluded = true;
                    }
                }
            }

            return customizerSection;
        });

        setCustomizerSections(sections);
    };

    const productId = window.rgb_customizer_widget.PRODUCT_ID;

    useEffect(() => {
        fetchCustomizer();
    }, []);

    useEffect(() => {
        excludeNonMatchingSections();
    }, [variableState]);

    useEffect(() => {
        if (customizerSections) {
            updateSectionVariableState();
        }
    }, [isDataFetched]);

    const fetchCustomizer = () => {
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
        const buildId = Number(urlParams.get('build_id'));
        const apiEndpoint = buildId ? `build/${buildId}/customizer` : `product/${productId}/customizer`;

        Http.get(apiEndpoint).then(res => {
            setCustomizerId(res.data.id);
            setCustomizerProduct(res.data.product);
            setCustomizerSections(res.data.sections);
            setIsDataFetched(true);
        }).catch(err => {
            console.error('APP ERROR', err);
            alert('There was an error loading the build customizer, please contact support');
        });
    };

    const setRandomSelections = () => {
        const sections = customizerSections?.map(customizerSection => {
            customizerSection.selected_option = customizerSection.options[
                Math.floor(Math.random() * customizerSection.options.length)];

            return customizerSection;
        });

        setCustomizerSections(sections);
    };

    if (customizerSections) {
        return (
            <customizerContext.Provider value={{
                id: customizerId,
                sections: customizerSections,
                product: customizerProduct,
                selectedSection,
                setSelectedSection,
                setSelectedSectionOption,
            }}>
                {process.env.NODE_ENV != 'production' && (
                    <button
                        onClick={() => setRandomSelections()}>
                        Set random selections
                    </button>
                )}
                <modalContext.Provider value={{
                    activeModal,
                    setActiveModal,
                    modalData,
                    setModalData,
                }}>
                    <div className="RGB-CustomizerWidget">
                        <CustomizerHeader/>

                        <div className="RGB-Container">
                            <Customizer/>
                            <CustomizerOptionPicker/>
                        </div>
                    </div>
                    <MoreInfoModal/>
                    <AvailableOptionsMobileModal/>
                </modalContext.Provider>
            </customizerContext.Provider>
        );
    }

    return (
        <div>Loading...</div>
    );
}
