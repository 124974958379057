/* eslint-disable max-len */
const LightIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={8}
        height={13}
        viewBox="0 0 8 13"
    >
        <g>
            <g>
                <g>
                    <path d="M1.12 1.225h2.657v3.033a.402.402 0 0 0 .118.29c.105.077.182.119.29.118h2.196L3.397 10.1V6A.406.406 0 0 0 3 5.59H1.12zM3 12.095a.44.44 0 0 0 .354-.212l4.092-7.435c.074-.126.074-.282 0-.448a.499.499 0 0 0-.348-.16H4.593V.817A.408.408 0 0 0 4.185.41H.718A.402.402 0 0 0 .31.818V6a.405.405 0 0 0 .408.405h1.87v5.299c0 .186.128.348.309.39a.324.324 0 0 0 .103 0z" />
                </g>
            </g>
        </g>
    </svg>
);

export default LightIcon;
