import React, {useContext, useEffect, useState} from "react";
import {customizerContext} from "@widget/contexts/CustomizerContext";
import {modalContext} from "@/widget/contexts/ModalContext";
import SummaryModal from "./Modals/SummaryModal";
import Cart from "@/lib/Cart";
import Http from "@widget/lib/Http";
import GaugeChart from 'react-gauge-chart';
import useIsMobile from "@widget/hooks/useIsMobile";
import classNames from "classnames";
import ToadIcon from "@widget/icons/ToadIcon";
import LightIcon from "@widget/icons/LightIcon";

const PRODUCT_TYPES = ['gaming pc', 'streaming pc'];

export default function CustomizerHeader() {
    const {id, product, sections} = useContext<BuildCustomizerContext>(customizerContext);
    const {activeModal, setActiveModal} = useContext<BuildModalContext>(modalContext);
    const [addToCartText, setAddToCartText] = useState<string>('Add To Cart');
    const [addingToCart, setAddingToCart] = useState<boolean>(false);
    const isMobile = useIsMobile();

    let productTitle = product?.title.toLowerCase();
    let productType = null;

    for (let type of PRODUCT_TYPES) {
        if (productTitle?.includes(type)) {
            productTitle = productTitle.replace(type, '');
            productType = type;
        }
    }

    const currentScore = (): number => sections
        .filter(section => section.selected_option && !section.excluded)
        .map(section => section.selected_option?.performance_points || 0)
        .reduce((carry, value) => carry + value, 0);

    const score = Math.min(Math.max(currentScore() - 50, 0) * 2, 100);

    const gaugePercentage = (): number => Math.min(score / 100, 1);
    const totalPrice = (): number => sections
        .filter(section => section.selected_option && !section.excluded)
        .map(section => section.selected_option?.products
            .map(product => product.price || 0)
            .reduce((total, price) => total + price, 0) || 0
        )
        .reduce((total, price) => total + price, 0) || 0;

    const canAddToCart = (): boolean => {
        const nonExcludedSections = sections.filter(section => !section.excluded);
        const sectionsWithSelectedOptions = sections.filter(section => section.selected_option && !section.excluded);

        return nonExcludedSections.length === sectionsWithSelectedOptions.length;
    };

    const hasSectionsWithNoPurchasableOptions = () => {
        const nonExcludedSections = sections.filter(section => !section.excluded);

        for (let section of nonExcludedSections) {
            const purchasableOptionsCount = section.options.filter(option => {
                const purchasableProducts = option.products.filter((product) => {
                    return product.inventory_quantity > 0
                        || product.inventory_policy == 'continue'
                        || !product.inventory_tracked;
                });

                return purchasableProducts.length === option.products.length;
            }).length;

            if (purchasableOptionsCount === 0) {
                return true;
            }
        }

        return false;
    };

    useEffect(() => {
        if (window.affirm) {
            console.log('running window.affirm refresh');
            window.affirm.ui.refresh();
        }
    }, [sections]);

    const addToCart = async() => {
        if (!canAddToCart()) {
            alert('Error, please select at least one option from all sections.');
            return;
        }
        setAddToCartText('Adding To Cart');
        setAddingToCart(true);

        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
        let buildId = Number(urlParams.get('build_id'));

        const buildResponse = buildId
            ? await Http.put(`customizer/${id}/build/${buildId}`, {
                product_data: product,
                customizer_data: sections.filter(section => !section.excluded && section.selected_option),
            })
            : await Http.post(`customizer/${id}/build`, {
                product_data: product,
                customizer_data: sections.filter(section => !section.excluded && section.selected_option),
            });

        buildId = buildResponse.data.id;

        await Cart.removeBuildItems(buildId);
        let section = sections[0];
        const customizer = await Http.get(`build/customizer/${section.customizer_id}`);
        const lineItems = sections
            .filter(section => !section.excluded && section.selected_option)
            .map(section => {
                return section.selected_option?.products.map(optionProduct => ({
                    quantity: 1,
                    id: optionProduct.shopify_variant_id,
                    properties: {
                        _build_id: buildId,
                        _addon_product: false,
                        _customizer_has_addons:customizer.data.has_addons,
                        _customizer_id: section.customizer_id,
                        _customizer_product_id: product!.shopify_id,
                        _customizer_product_handle: product!.handle,
                        _customizer_product_title: product!.title,
                        _customizer_product_image: product!.image_url,
                        _customizer_section_id: section.id,
                        _customizer_section_title: section.title,
                        _customizer_option_id: section.selected_option?.id,
                        _customizer_option_title: section.selected_option?.title,
                        _customizer_option_image_url: optionProduct.image_url,
                    }
                }));
            })
            .reduce((item, nextItem) => item!.concat(nextItem!));

        await Cart.add(lineItems!);

        urlParams.set('build_id', buildId.toString());

        window.history.pushState({}, '', window.location.href + '?' + urlParams.toString());
        window.location.href = '/cart';
    };

    return (
        <div className="RGB-CustomizerHeader RGB-Container">
            <div className="RGB-CustomizerHeader__FirstColumn RGB-Container__Column">
                <div className="RGB-Container">
                    <div className="RGB-Container__Column RGB-mr1 RGB-CustomizerHeader__FirstColumn__Left">
                        <div className="RGB-Card">
                            <div className="d3 RGB-Card-Header">
                                Selected base build
                            </div>
                            <div className="RGB-Card-Body">
                                <div className="RGB-Card-Content" style={{alignItems: 'center'}}>
                                    <div className="RGB-Card-Content-Left" style={{flex: '45%'}}>
                                        <img src={product?.image_url} style={{width: '150px'}} alt=""/>
                                    </div>
                                    <div className="RGB-Card-Content-Right" style={{flex: '45%'}}>
                                        <div className="d2">{productTitle?.toUpperCase()}</div>
                                        <div className="b2">{productType?.toUpperCase()}</div>
                                        <div>
                                            <a href="/collections/all">
                                                <button className="RGB-btn RGB-mt1">
                                                    Change
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(hasSectionsWithNoPurchasableOptions() && !isMobile) && (
                        <div
                            className={classNames({
                                'RGB-Container__Column': true,
                                'RGB-CustomizerHeader__Column': true,
                                'RGB-CustomizerHeader__OutOfStock': true,
                                'RGB-CustomizerHeader__FirstColumn__Right': true,
                                'RGB-DisplayNone': isMobile && activeModal
                            })}>
                            <div className="RGB-Card">
                                <div className="d3 RGB-Card-Header">
                                    Component(s) Out Of Stock
                                </div>
                                <div className="RGB-Card-Body">
                                    <div className="RGB-Card-Content" style={{textAlign: 'center'}}>
                                        <div style={{margin: '0 auto'}}>
                                            <p className="RGB-mt1 RGB-mb1" style={{marginTop: '2em'}}>
                                                One of the components in this build has
                                                <strong> no options available</strong> and
                                                can't be purchased right now
                                            </p>
                                        </div>

                                        <div style={{margin: '0 auto'}}>
                                            <button className="RGB-btn RGB-btn--purpleBorder" style={{
                                                marginTop: '2em', marginBottom: '2em'}
                                            }>
                                            View Other Builds
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!hasSectionsWithNoPurchasableOptions() && (
                        <div
                            className={classNames({
                                'RGB-Container__Column': true,
                                'RGB-CustomizerHeader__Column': true,
                                'RGB-CustomizerHeader__PerformanceCard': true,
                                'RGB-CustomizerHeader__FirstColumn__Right': true,
                                'RGB-DisplayNone': isMobile && activeModal
                            })}>
                            <div className="RGB-Card">
                                <div className="d3 RGB-Card-Header">
                                    <span className="RGB-Icon"><ToadIcon /></span> Play Score
                                </div>
                                <div className="RGB-Card-Body">
                                    <div className="RGB-Card-Content">
                                        <div className="RGB-Card-Content-Left" style={{width: '40%'}}>
                                            <div className="RGB-Gauge">
                                                <GaugeChart
                                                    id="gauge-chart5"
                                                    className="RGB-CustomizerHeader__PerformanceCard__GaugeChart"
                                                    nrOfLevels={420}
                                                    arcsLength={[0.3, 0.33, 0.3]}
                                                    colors={['#B0C1EF', '#839FEF', '#4B5FF2']}
                                                    percent={gaugePercentage()}
                                                    arcPadding={0}
                                                    marginInPercent={0.08}
                                                    arcWidth={0.4}
                                                    cornerRadius={0}
                                                    needleColor={'#fff'}
                                                    needleBaseColor={'#fff'}
                                                    hideText={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="RGB-Card-Content-Right">
                                            <div className="RGB-CustomizerHeader__CurrentScore">
                                                <div className="b2 RGB-CustomizerHeader__CurrentScore__Label">
                                                    Current Score
                                                </div>
                                                <div className="d1 d1-static RGB-CustomizerHeader__CurrentScore__Value">
                                                    {currentScore()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="RGB-CustomizerHeader__PerformanceCard__Info">
                                            <span className="RGB-Icon"><LightIcon/> </span>
                                            <span className="RGB-CustomizerHeader__PerformanceCard__Info__HelpText">
                                                Increase the performance by upgrading the components below
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="RGB-Container__Column">
                {!hasSectionsWithNoPurchasableOptions() && (
                    <div
                        className={classNames({
                            'RGB-Container__Column': true,
                            'RGB-CustomizerHeader__Column': true,
                            'RGB-CustomizerHeader__SummaryCard': true,
                            'RGB-DisplayNone': isMobile && activeModal
                        })}
                        style={{flex: '33%'}}>
                        <div className="RGB-Card">
                            <div className={classNames({
                                d3: true,
                                'RGB-Card-Header': true,
                                'RGB-DisplayNone': isMobile
                            })}>
                                Total Cost
                            </div>
                            <div className="RGB-Card-Body">
                                <div className="
                                    RGB-CustomizerHeader__Summary
                                    RGB-CustomizerHeader__Summary__SummaryContainer
                                    RGB-Container">
                                    <div className="
                                        RGB-MobileHidden RGB-CustomizerHeader__TotalPrice RGB-Container__Column
                                    ">
                                        ${totalPrice()?.toFixed(2)}
                                    </div>
                                    <div style={{width: '100%'}} className="RGB-mt1 RGB-mb1">
                                        <p
                                            className="affirm-as-low-as"
                                            data-amount={totalPrice() * 100}
                                            data-affirm-type="logo"
                                            data-page-type="product"
                                            data-affirm-color="white"
                                            style={{fontSize: '14px'}}/>
                                        <span id="klarna-placement"/>
                                    </div>
                                    <br />

                                    <div
                                        className="
                                            RGB-MobileHidden RGB-Container__Column
                                            RGB-CustomizerHeader__Summary__ViewSummaryBtn
                                        ">
                                        <button
                                            className={classNames({
                                                'RGB-btn': !isMobile,
                                                'RGB-btn--redBorder': !isMobile,
                                                'RGB-btn--plain': isMobile,
                                            })}
                                            onClick={
                                                () => setActiveModal('SummaryModal')
                                            }>
                                            View Summary
                                        </button>
                                    </div>
                                    <div className="RGB-MobileHidden RGB-Container__Column">
                                        <button
                                            className="RGB-btn RGB-btn--addToCart"
                                            disabled={!canAddToCart() || addingToCart}
                                            onClick={() => addToCart()}>
                                            {addToCartText}
                                        </button>
                                    </div>

                                    <button
                                        className="
                                            RGB-DesktopHidden
                                            RGB-btn
                                            RGB-btn--noborder
                                            RGB-btn--addToCart
                                            RGB-CustomizerHeader__Summary__AddToCartBtn
                                        "
                                        disabled={!canAddToCart() || addingToCart}
                                        onClick={() => addToCart()}>
                                        {addToCartText} - ${totalPrice()?.toFixed(2)}
                                    </button>

                                    <div style={{width: '85%'}} className="RGB-DesktopHidden RGB-mt1">
                                        <button
                                            className={classNames({
                                                'RGB-btn': !isMobile,
                                                'RGB-btn--redBorder': !isMobile,
                                                'RGB-btn--plain': isMobile,
                                                'RGB-mr1': true,
                                            })}
                                            onClick={
                                                () => setActiveModal('SummaryModal')
                                            }>
                                            View Summary
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <SummaryModal onAddToCart={() => addToCart()}></SummaryModal>
        </div>
    );
}
