import React from "react";

interface Props {
    section: BuildCustomizerSection;
}

export default function OptionSummaryCard({section}: Props) {
    return (
        <div className="RGB-OptionSummaryCard">
            <div className="RGB-Card">
                <div className="RGB-Card-Header">Selected {section.title}</div>
                <div className="RGB-Card-Body">
                    <div className="RGB-Card-Content">
                        <div className="RGB-Card-Content-Left">
                            <img className="RGB-OptionSummaryCard__ProductImage" src={section.selected_option?.image}/>
                        </div>
                        <div className="d5 RGB-Card-Content-Right">
                            {section.selected_option?.title}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
