import React, {useContext} from "react";
import {customizerContext} from "@widget/contexts/CustomizerContext";
import CustomizerOption from "@widget/components/CustomizerOption";
import useIsMobile from "@widget/hooks/useIsMobile";
import {modalContext} from "@widget/contexts/ModalContext";

export default function CustomizerOptionPicker() {
    const {selectedSection} = useContext<BuildCustomizerContext>(customizerContext);
    const isMobile = useIsMobile();
    const {activeModal} = useContext<BuildModalContext>(modalContext);

    /**
     * Get options where all component products are in stock
     */
    const optionsWithStock = selectedSection?.options.filter(option => {
        return option.products.filter(product => {
            return product.inventory_quantity > 0
                || product.inventory_policy == 'continue'
                || !product.inventory_tracked;
        }).length > 0 || option.products.length == 0;
    });

    if (isMobile && activeModal !== 'AvailableOptionsMobileModal') {
        // This ensures nothing gets displayed outside the modal on mobile
        return null;
    }

    return (
        <>
            <div className="RGB-CustomizerOptionPicker RGB-Container__Column">
                <div className="RGB-Card">
                    {!isMobile && (
                        <div className="d3 RGB-Card-Header">Available Options</div>
                    )}
                    <div className="RGB-Card-Body">
                        {selectedSection && (
                            <div className="RGB-CustomizerOptionPicker__Option RGB-Scrollable__scroll">
                                <div className="b3 RGB-mb1">{selectedSection.title} Selection</div>
                                {optionsWithStock!
                                    .sort((a, b) => a.position > b.position ? 1 : -1)
                                    .map((option) => (
                                        <CustomizerOption
                                            section={selectedSection}
                                            option={option}
                                            key={option.id}/>
                                    ))}
                            </div>
                        )}
                        {!selectedSection && (
                            <div className="RGB-CustomizerOptionPicker__HelpText">
                                <p className="b3">Customization options will be displayed here.</p>
                                <p className="b3">
                                    To get started simply choose a component that can be customized,
                                    click ‘CHANGE’ and select an option to replace it with.
                                </p>
                                <p className="b3">
                                    Each component has been given performance points which,
                                    when added to your custom build, will increase the play score.
                                    The higher the play score the better estimated performance.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
