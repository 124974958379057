import React, {useContext, useState} from 'react';
import {Dialog} from '@headlessui/react';

import {modalContext} from "@/widget/contexts/ModalContext";
import {customizerContext} from '@/widget/contexts/CustomizerContext';
import useIsMobile from "@widget/hooks/useIsMobile";
import ToadIcon from '@/widget/icons/ToadIcon';
import CloseIcon from "@widget/icons/CloseIcon";

export default function MoreInfoModal() {
    const {activeModal, setActiveModal, modalData} = useContext<BuildModalContext>(modalContext);
    const {setSelectedSectionOption} = useContext<BuildCustomizerContext>(customizerContext);
    const [loading, setLoading] = useState<boolean>(false);
    const isMobile = useIsMobile();

    const section = modalData.section as BuildCustomizerSection;
    const option = modalData.option as BuildCustomizerOption;

    const isActive = activeModal === 'MoreInfoModal';

    const closeModal = () => {
        setActiveModal(null);
    };

    const selectOption = () => {
        setLoading(true);
        setSelectedSectionOption(section, option);
        closeModal();
        setLoading(false);
    };

    const totalPrice = option?.products.map(product => {
        return product.price;
    }).reduce((prevValue, newValue) => prevValue + newValue, 0);

    return (
        <Dialog
            open={isActive}
            as="div"
            className="RGB-Dialog"
            onClose={closeModal}
        >
            <div className="RGB-Dialog__Main">
                <Dialog.Overlay className="RGB-Dialog__Overlay"/>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="RGB-Dialog__Center" aria-hidden="true">&#8203;</span>

                <div className="RGB-Dialog__Card RGB-Dialog__MoreInfoModal__Container">
                    <Dialog.Title as="h3" className="d2 RGB-Dialog__Card__Title" style={{marginBottom: '2em'}}>
                        More Info <button className="RGB-Dialog__CloseBtn" onClick={closeModal}><CloseIcon/></button>
                    </Dialog.Title>
                    <div className="RGB-Dialog__Card__Body" style={{marginTop: '0.5rem'}}>
                        <div className="RGB-Container">
                            <div className="
                                RGB-Container__Column RGB-f40 RGB-mr1 RGB-df
                                RGB-Dialog__MoreInfoModal__ImageContainer RGB-Scrollable__scroll
                            ">
                                {option?.images.map((image: string) => (
                                    <img className="RGB-Dialog__MoreInfoModal__Image" src={image}/>
                                ))}
                            </div>
                            <div className="RGB-Container__Column RGB-f50">
                                <div className="d3 RGB-mb1">{option?.title}</div>
                                <div>
                                    <div className="d2 RGB-inl RGB-mr1">+ ${totalPrice}</div>
                                    <div className="d3 RGB-inl RGB-ml1">
                                        <span className="RGB-Icon RGB-mr05">
                                            <ToadIcon/>
                                        </span> +{option?.performance_points} Performance Points
                                    </div>
                                </div>

                                <div className="RGB-Dialog__MoreInfoModal__Description">
                                    <div dangerouslySetInnerHTML={{__html: option?.description}}/>
                                </div>

                                <button
                                    type="button"
                                    className="RGB-btn RGB-mt1 RGB-btn--purpleBorder"
                                    style={{width: '150px'}}
                                    onClick={selectOption}>
                                    {loading ? '...' : 'Select'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
