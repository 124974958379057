import React, {useContext} from "react";
import {customizerContext} from "@widget/contexts/CustomizerContext";
import {modalContext} from "@widget/contexts/ModalContext";
import ToadIcon from "@widget/icons/ToadIcon";

interface Props {
    section: BuildCustomizerSection;
    option: BuildCustomizerOption;
}

export default function CustomizerOption({section, option}: Props) {
    const {setSelectedSectionOption} = useContext<BuildCustomizerContext>(customizerContext);
    const {setActiveModal, setModalData} = useContext<BuildModalContext>(modalContext);

    const totalPrice = option.products
        .map(product => product.price)
        .reduce((prevValue, newValue) => prevValue + newValue, 0);

    const activeOptionTotalPrice = section.selected_option?.products
        .map(product => product.price)
        .reduce((prevValue, newValue) => prevValue + newValue, 0) || 0;

    const relativePrice = totalPrice > activeOptionTotalPrice
        ? totalPrice - activeOptionTotalPrice
        : activeOptionTotalPrice - totalPrice;

    const currentOptionSelected = section.selected_option?.id == option.id;

    const optionImageSrc = (option?.products || []).length > 0
        ? option?.image
        : window.rgb_customizer_widget.APP_ASSET_URL + 'None-Selected.png';

    return (
        <div className="RGB-CustomizerOption RGB-mb2">
            <div className="RGB-Card RGB-CardMobile">
                <div
                    className={`
                        RGB-Card-Header
                        RGB-CustomizerOption__Header
                        ${currentOptionSelected ? "RGB-Card-Header--optionSelected" : "RGB-Card-Header--selected"}
                    `}>
                    <span className="RGB-CustomizerOption__Title">{section.title}</span>
                    {option.performance_points >= 0 && (
                        <span className="b2 RGB-CustomizerOption__PointsHeading">
                            <span className="RGB-Icon RGB-mr05">
                                <ToadIcon />
                            </span> +{option.performance_points} Performance Points
                        </span>
                    )}
                </div>
                <div className="RGB-Card-Body RGB-Card-Body--light">
                    <div className="RGB-Card-Content">
                        <div className="RGB-Card-Content-Left RGB-df">
                            <img className="RGB-OptionImage" src={optionImageSrc}/>
                        </div>
                        <div className="RGB-Card-Content-Right">
                            <div className="d5">{option.title}</div>
                            <div className="b3 RGB-CustomizerOption__TotalPrice">
                                {relativePrice != 0 && section.selected_option  && (
                                    totalPrice > activeOptionTotalPrice ? '+' : '-' + ' '
                                )}

                                {relativePrice == 0 ? '' : '$' + relativePrice.toFixed(2)}
                            </div>
                            <div className="RGB-CustomizerSection__BtnHolder">
                                <button
                                    className={`
                                        RGB-btn RGB-mr1 RGB-btn--primary
                                        ${currentOptionSelected ? "RGB-btn--optionSelected" : "RGB-btn--selected"}
                                    `}
                                    onClick={() => {
                                        setSelectedSectionOption(section, option);
                                        setActiveModal(null);
                                    }}>
                                    {currentOptionSelected ? 'Selected' : 'Select'}
                                </button>
                                {option.products.length > 0 && (
                                    <button
                                        className="RGB-btn RGB-btn--primary"
                                        onClick={() => {
                                            setActiveModal('MoreInfoModal');
                                            setModalData({
                                                section: section,
                                                option: option,
                                            });
                                        }}>
                                        More info
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
                {option.promotional_text && (
                    <div className="RGB-CustomizerOption__PromoText">
                        <div dangerouslySetInnerHTML={{__html: option.promotional_text}}/>
                    </div>
                )}
            </div>
        </div>
    );
}
