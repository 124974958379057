import React, {useContext} from "react";
import {customizerContext} from "@widget/contexts/CustomizerContext";
import {modalContext} from "@widget/contexts/ModalContext";
import useIsMobile from "@widget/hooks/useIsMobile";
import ToadIcon from "@widget/icons/ToadIcon";

interface Props {
    section: BuildCustomizerSection;
}

export default function CustomizerSection({section}: Props) {
    const {setSelectedSection} = useContext<BuildCustomizerContext>(customizerContext);
    const {setActiveModal, setModalData} = useContext<BuildModalContext>(modalContext);
    const isMobile = useIsMobile();

    const changeSelectedOption = () => {
        setSelectedSection(section);
        if (isMobile) {
            setActiveModal('AvailableOptionsMobileModal');
        }
    };

    const totalPrice = section.selected_option?.products
        .map(product => product.price)
        .reduce((prevValue, newValue) => prevValue + newValue, 0);

    const defaultOption = section.options.filter((option) => {
        return section.default_option_id === option.id;
    });

    const defaultOptionPrice = defaultOption[0]?.products
        .map(product => product.price)
        .reduce((prevValue, newValue) => prevValue + newValue, 0) || 0;

    const relativePrice = totalPrice! > defaultOptionPrice
        ? totalPrice! - defaultOptionPrice
        : defaultOptionPrice - totalPrice!;

    const selectedOptionImageSrc = (section.selected_option?.products || []).length > 0
        ? section.selected_option?.image
        : window.rgb_customizer_widget.APP_ASSET_URL + 'None-Selected.png';

    const cardContent = section.selected_option
        ? () => (
            <div className="RGB-Card-Content">
                <div className="RGB-Card-Content-Left RGB-df">
                    <img className="RGB-OptionImage" src={selectedOptionImageSrc} alt=""/>
                </div>

                <div className="RGB-Card-Content-Right">
                    <div>
                        <div>
                            <span className="d5">{section.selected_option?.title}</span>
                            <div className="b3 RGB-CustomizerOption__TotalPrice">
                                {relativePrice != 0 && section.selected_option  && (
                                    totalPrice! > defaultOptionPrice ? '+' : '-' + ' '
                                )}

                                {relativePrice == 0 ? '' : '$' + relativePrice.toFixed(2)}
                            </div>
                        </div>
                        <div className="RGB-CustomizerSection__BtnHolder">
                            {!section.default_option_fixed && (
                                <button
                                    className="
                                        RGB-CustomizerSection__BtnHolder__ChangeBtn RGB-btn RGB-btn--primary
                                        RGB-btn--selected"
                                    onClick={() => changeSelectedOption()}>
                                    Change
                                </button>
                            )}
                            {(section.selected_option?.products || []).length > 0 && (
                                <button
                                    className="RGB-btn RGB-btn--primary"
                                    onClick={() => {
                                        setActiveModal('MoreInfoModal');
                                        setModalData({
                                            section: section,
                                            option: section.selected_option,
                                        });
                                    }}>
                                    More info
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ) : () => (
            <div className="RGB-Card-Content">
                <button
                    style={{margin: '0 auto'}}
                    className="RGB-btn RGB-btn--primary"
                    onClick={() => changeSelectedOption()}>
                    View Options
                </button>
            </div>
        );

    return (
        <div className="RGB-CustomizerSection RGB-Container__Column">
            <div className="RGB-Card">
                <div className={`
                    RGB-Card-Header
                    RGB-CustomizerOption__Header
                    ${section.selected_option ? "RGB-Card-Header--selected" : "RGB-Card-Header--unselected"}
                `}>
                    <span className="RGB-CustomizerOption__Title">{section.title}</span>
                    {section.selected_option && section.selected_option?.performance_points >= 0 && (
                        <span className="b2 RGB-CustomizerOption__PointsHeading">
                            <span className="RGB-Icon RGB-mr05">
                                <ToadIcon />
                            </span> +{section.selected_option.performance_points} Performance Points
                        </span>
                    )}
                </div>
                <div className={`
                    RGB-Card-Body
                    ${section.selected_option ? "RGB-Card-Body--selected" : "RGB-Card-Body--unselected"}
                `}>
                    {cardContent()}
                </div>
            </div>
        </div>
    );
}
