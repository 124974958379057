import React, {useContext, useState} from 'react';
import {Dialog} from '@headlessui/react';

import {modalContext} from "@/widget/contexts/ModalContext";
import {customizerContext} from "@/widget/contexts/CustomizerContext";
import OptionSummaryCard from '@/widget/components/OptionSummaryCard';
import useIsMobile from "@widget/hooks/useIsMobile";
import classNames from "classnames";
import CloseIcon from '@/widget/icons/CloseIcon';

interface Props {
    onAddToCart: () => void;
}

export default function SummaryModal({onAddToCart}: Props) {
    const {activeModal, setActiveModal} = useContext<BuildModalContext>(modalContext);
    const {sections, product} = useContext<BuildCustomizerContext>(customizerContext);
    const [loading, setLoading] = useState<boolean>(false);
    const isMobile = useIsMobile();

    const sectionsWithSelectedOption = sections.filter(section => (section.selected_option && !section.excluded));

    const canAddToCart = (): boolean => {
        const nonExcludedSections = sections.filter(section => !section.excluded);
        const sectionsWithSelectedOptions = sections.filter(section => section.selected_option && !section.excluded);

        return nonExcludedSections.length === sectionsWithSelectedOptions.length;
    };

    const closeModal = () => {
        setActiveModal(null);
    };

    const addToCart = () => {
        setLoading(true);
        onAddToCart();
        setLoading(false);
    };

    return (
        <Dialog
            open={activeModal === 'SummaryModal'}
            as="div"
            className="RGB-Dialog"
            onClose={closeModal}
        >
            <div className="RGB-SummaryModal">
                <div className="RGB-Dialog__Main">
                    <Dialog.Overlay className="RGB-Dialog__Overlay"/>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="RGB-Dialog__Center" aria-hidden="true">&#8203;</span>

                    <div className="RGB-Dialog__Card">
                        <Dialog.Title as="h3" className="d3 RGB-Dialog__Card__Title">
                            PC Customizer <button className="RGB-Dialog__CloseBtn" onClick={closeModal}>
                                <CloseIcon/>
                            </button>
                        </Dialog.Title>
                        <div className="RGB-Dialog__Card__Body" style={{marginTop: '0.5rem'}}>
                            <div className="d2 RGB-Dialog__Card__Title">
                                Summary Of Your {product?.title ?? 'PC'} Customization
                            </div>
                            <div className="RGB-Dialog__Card__Sections RGB-Scrollable__scroll">
                                {sectionsWithSelectedOption.length === 0 && (<div>No items selected</div>)}
                                {sectionsWithSelectedOption.map((section) => (
                                    <OptionSummaryCard
                                        section={section}
                                        key={section.id}/>
                                ))}
                            </div>

                            <div className="RGB-Dialog__Footer">
                                <div className="RGB-Container">
                                    <div className="RGB-Container__Column">
                                        <button
                                            type="button"
                                            className={classNames({
                                                b3: true,
                                                'RGB-btn': true,
                                                'RGB-btn--redBorder': true,
                                                'RGB-btn--mla': !isMobile,
                                                'RGB-mr1': true
                                            })}
                                            onClick={closeModal}>
                                            Edit Build
                                        </button>
                                    </div>

                                    <div className="RGB-Container__Column">
                                        <button
                                            type="button"
                                            className={classNames({
                                                b3: true,
                                                'RGB-btn': true,
                                                'RGB-btn--addToCart': true,
                                                'RGB-btn--mra': !isMobile,
                                                'RGB-ml1': true
                                            })}
                                            disabled={!canAddToCart()}
                                            onClick={() => addToCart()}>
                                            {loading ? 'Adding...' : 'Add to cart'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
